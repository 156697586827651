import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import moment from "moment";
import { useSpinner } from "../../../context/SpinnerContext";
import SpinnerButton from "../../../Componet/Button/SpinnerButton";

import HerosectionHeader from "../../../Componet/Herosection/HerosectionHeader";
import { InspectorStatus } from '../../../store/Utility/Status';
import { InspectorStatusData } from "../../../store/Utility/Utility";
import EmptyDataResult from "../../../Componet/EmptyDataResult";


const InspectorList = () => {
    const [inspectors, setInspectors] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(InspectorStatus.Applicant);
    const [loading, setLoading] = useState(false);
    const { showLoader, hideLoader } = useSpinner();

    const fetchInspectors = async (e) => {
        setLoading(true);
        await axios.get(`v1/inspector/getByStatus`,
            { params: { status: (selectedStatus != undefined || selectedStatus != null) ? selectedStatus : InspectorStatus.AwaitingApproval } }).then(res => {
                setLoading(false);
                setInspectors(res?.data?.data);
            });
    };


    const handleOptionChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    return (<>
        <HerosectionHeader name={"Inspectors"} />
        <div class="container">
            <Row>
                <Col md={6}>
                    <div className="mt-5 ms-4 d-flex align-items-center">
                        <h5>{InspectorStatusData && InspectorStatusData.find((t) => t.id == selectedStatus).displayName}</h5>
                    </div>
                </Col>
                <Col md={2}><div className="mt-5 d-flex align-items-center"><span>Result: {inspectors?.length}</span></div></Col>
                <Col md={2}>
                    <div className="mt-5 d-flex align-items-center">
                        <select
                            className="form-select"
                            id="inputGroupSelect02"
                            onChange={handleOptionChange}
                            value={selectedStatus}
                        >
                            {InspectorStatusData && InspectorStatusData.length > 0 && InspectorStatusData.map((m) => (
                                <option value={m.id}>{m.displayName}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col md={2}>
                    <div className="mt-5 d-flex">
                        <SpinnerButton type="button" className="btn btn-primary btn-sm" loading={loading} onClick={fetchInspectors} text="Search" />
                    </div>
                </Col>
            </Row>
            <div class="row">
                <div class="col">
                    {(inspectors && inspectors.length > 0) ?
                        inspectors.map((item, index) => ((
                            <div class="card bg-transparent flex-md-row align-items-center p-md-0 3 mt-3 mb-3">
                                <div class="col-md-2">
                                    <div className="bg-primary p-2 d-inline-block rounded m-4">
                                        <div className="f-icon-m text-white">

                                            <i className={item.status == InspectorStatus.Approved ? "la la-user-check" :
                                                item.status == InspectorStatus.Applicant ? "la la-user-clock" :
                                                    item.status == InspectorStatus.AwaitingContract ? "la la-user-edit" :
                                                        item.status == InspectorStatus.Suspended ? "la la-user-minus" : "la la-user"}></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body col-md-6">
                                    <div>
                                        <div class="d-inline-block bg-light text-center rounded">
                                            {/* <span class="text-primary">{VehicleTypes.find(t => t.id == job.vehicleType).name}</span> */}
                                        </div>
                                        <span>{item.state} / {item.city} / {item.zipCode} / SZC:{item.serviceZipCode}</span>

                                    </div>
                                    <h2 class="h5 my-2">
                                        <a class="link-title">{item.firstName} {item.lastName}</a>
                                    </h2>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item pe-3">
                                            <a class="list-group-item-action">
                                                <i class="lar la-calendar-info ms-1 text-primary ic-1x"></i>
                                                {moment(item.createdDate).format('MM.DD.YYYY')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-2">
                                    <Link className='btn btn-primary' to={`/inspector/detail/${item.id}`}> Detail</Link>
                                </div>
                            </div>))) : <div> <EmptyDataResult message="There is no data to display" /></div>
                    }

                </div>
            </div>
        </div>
    </>);

}

export default InspectorList;