import React, { useState, useRef, useEffect } from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import PhoneMaskInput from "../../../Componet/PhoneMaskInput";

import Modal from 'react-bootstrap/Modal';
import HerosectionHeader from "../../../Componet/Herosection/HerosectionHeader"; //"../../Componet/Herosection/HerosectionHeader";

import axios from "../../../api/axios";
import { isValidEmail, isValidZipCode, isValidPhoneNumber } from "../../../store/Utility/Helper";
import { useSpinner } from "../../../context/SpinnerContext";
import { useLocation } from "react-router-dom";
import { MAX_LENGTH } from '../../../store/Utility/Constants';
import Options from "../../../Componet/Option/Options";
import { States } from "../../../store/Utility/Data";
import LocationAutocomplete from '../../../Componet/Autocomplete/LocationAutocomplete';
import AutoCompleteInputFormGrouped from '../../../Componet/Autocomplete/AutoCompleteInputFormGrouped';

const InitialData = {
    firstName: "",
    middleName: null,
    lastName: "",
    email: "",
    phone: "",
    address: "",
    addressUnit: null,
    city: "",
    state: "",
    zipCode: "",
    experiencedBrandFirst: "",
    experiencedBrandSecond: "",
    experiencedBrandThird: "",
    aseCertification: "",
    workExperience: "",
    education: "",
    otherCertifications: null,
    referralCode: null,
    serviceLatitude: null,
    serviceLongitude: null
}

function Inspector() {
    const addressRef = useRef();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    InitialData.referralCode = code;
    const [formData, setFormData] = useState(InitialData);
    const [modal, showModal] = useState({ show: false, message: null })
    const [validationError, setValidationError] = useState({});
    const { showLoader, hideLoader } = useSpinner();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [makes, setMake] = useState();

    const handleClose = () => showModal({ show: false, message: null });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    const onPlaceChanged = (place) => {

        const addressComponents = place.address_components;


        var result = {
            city: formData.city,
            state: formData.state,
            zipCode: formData.zipCode,
            address: formData.address,
            serviceLatitude: formData.serviceLatitude,
            serviceLongitude: formData.serviceLongitude
        };

        const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
        if (state != null) {
            result.state = state.short_name;
        }
        const city = addressComponents.find(component => component.types.includes('locality'));
        if (city != null) {
            result.city = city.short_name;
        }

        if (place.formatted_address) {
            result.address = place.formatted_address;
        }

        const postalCode = addressComponents.find(component => component.types.includes('postal_code'));

        if (postalCode) {
            result.zipCode = postalCode.short_name;
        }

        if (place.geometry.location) {
            result.serviceLatitude = place.geometry.location.lat();
            result.serviceLongitude = place.geometry.location.lng();
        }

        if (Object.keys(result).length > 0) {
            setFormData((prevFormData) => ({ ...prevFormData, ...result }));
        }

    }

    const handleLimitedTextChange = (event) => {
        const { name, value } = event.target;
        if (value.length <= MAX_LENGTH) {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }
    }

    const handlePhoneChange = (event) => {
        const { name, value } = event.target;
        if (!isValidPhoneNumber(value)) {
            let errors = {};
            errors.phone = "Please enter valid phone number";
            setValidationError(errors);
        } else {
            setValidationError({})
        }
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    const handleEmailChange = (event) => {
        const { name, value } = event.target;
        if (!isValidEmail(value)) {
            let errors = {};
            errors.email = "Please enter valid email";
            setValidationError(errors);
        } else {
            setValidationError({})
        }
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    const handleZipCodeChange = (event) => {
        const { name, value } = event.target;
        if (!isValidZipCode(value)) {
            let errors = {};
            errors.zipCode = "Please enter valid Zip Code";
            setValidationError(errors);
        } else {
            setValidationError({})
        }
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }


    useEffect(() => {
        const getMakes = async () => {
            const response = await axios.get(`v1/make`).then(res => {
                setMake(res.data.data);
            });
        }
        getMakes();
    }, []);

    const onMakeClick = (clickedItem) => {
        setFormData({ ...formData, experiencedBrandFirst: clickedItem.name });
    }

    const onMakeChange = (event) => {
        handleChange(event);
    }


    const validateValues = (inputValues) => {
        let errors = {};

        if (inputValues.email == '') {
            errors.email = "Email is required";
        } else {
            if (!isValidEmail(inputValues.email)) errors.email = "Please enter valid email";
        }

        if (inputValues.phone == '') {
            errors.phone = "Phone is required";
        } else {
            if (!isValidPhoneNumber(inputValues.phone)) errors.phone = "Please enter valid phone number";
        }

        if (inputValues.zipCode == '') {
            errors.zipCode = "Zip Code is required";
        } else {
            if (!isValidZipCode(inputValues.zipCode)) errors.zipCode = "Please enter valid zip code";
        }

        return errors;
    };


    async function onSubmit(e) {
        e.preventDefault();
        var error = validateValues(formData);
        setValidationError(error);
        if (Object.keys(error).length != 0) return;

        const recaptchaToken = await executeRecaptcha("inspector");

        if (recaptchaToken == undefined || recaptchaToken == null || recaptchaToken == '') return;
        else {
            showLoader();
            const response = await axios.post(`v1/inspector`, { ...formData, recaptchaToken: recaptchaToken }).then(res => {
                hideLoader();
                if (res.data.data.status == 400) {
                    showModal({ show: true, message: 'An error occured while saving! Please try again later' });
                } else {
                    showModal({ show: true, message: "Your application successfully submitted!" });
                    if (addressRef.current) {
                        addressRef.current.value = ''; // Clear the input value
                    }
                    setFormData(InitialData);
                }

            }).catch(error => {
                hideLoader();
                showModal({ show: true, message: error.response?.data?.Error })
            });
        }
    }

    return (
        <div>
            <div className="page-wrapper">
                <HerosectionHeader name={"Become an Inspector"} />
                <div className="page-content">
                    {<Modal show={modal.show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Result</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{modal.message}</Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-dark" onClick={handleClose}>Close</button>
                        </Modal.Footer>
                    </Modal>}
                    <section>
                        <Container>
                            <Row >
                                <Col lg={12} md={12}>
                                    <div className="checkout-form border p-4 rounded">
                                        <h2 className="mb-4">Become an Inspector</h2>
                                        <div className="col-lg-12 col-12">
                                            <p className="lead mb-5">We're actively seeking highly skilled technicians to join our team. Please complete the form below,
                                                specifying three automotive brands you're most experienced with, among other requested information.
                                                If you meet our qualifications, we'll reach out to you shortly!</p>
                                        </div>
                                        <form onSubmit={onSubmit}>
                                            <Row form>
                                                <Col md={3}>

                                                    <FormGroup floating>
                                                        <Input
                                                            type="text"
                                                            id="firstName"
                                                            name="firstName"
                                                            placeholder="First Name"
                                                            value={formData.firstName}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <Label for="firstName">First Name *</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup floating>

                                                        <Input
                                                            type="text"
                                                            id="middleName"
                                                            placeholder="Middle Name"
                                                            name="middleName"
                                                            value={formData.middleName || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <Label for="middleName">Middle Name</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="text"
                                                            id="lastName"
                                                            name="lastName"
                                                            placeholder="Last Name"
                                                            value={formData.lastName}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <Label for="lastName">Last Name *</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="text"
                                                            id="email"
                                                            name="email"
                                                            placeholder="E-mail*"
                                                            value={formData.email}
                                                            onChange={handleEmailChange}
                                                            required
                                                        />
                                                        <Label for="email">Email *</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup floating>

                                                        <PhoneMaskInput
                                                            name="phone"
                                                            id="phone"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Phone"
                                                            value={formData.phone}
                                                            onChange={handlePhoneChange}
                                                            required
                                                        />
                                                        <Label for="phone">Phone *</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup floating>
                                                        <LocationAutocomplete inputRef={addressRef} onPlaceChanged={onPlaceChanged} defaultValue={formData.address} placeholder="Address *" />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="text"
                                                            id="addressUnit"
                                                            name="addressUnit"
                                                            placeholder="Unit"
                                                            value={formData.addressUnit || ''}
                                                            onChange={handleChange}
                                                        />
                                                        <Label for="addressUnit">Unit/Apt #</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="text"
                                                            id="city"
                                                            name="city"
                                                            placeholder="City"
                                                            className="custom-form-control"
                                                            value={formData.city}
                                                            onChange={handleChange}
                                                            disabled
                                                            required
                                                        />
                                                        <Label for="city">City *</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="select"
                                                            id="state"
                                                            name="state"
                                                            className="form-control custom-form-control"
                                                            value={formData.state}
                                                            onChange={handleChange}
                                                            disabled
                                                            required
                                                        >
                                                            <Options data={States} />
                                                        </Input>
                                                        <Label for="state">State *</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="text"
                                                            id="zipCode"
                                                            name="zipCode"
                                                            className="custom-form-control"
                                                            value={formData.zipCode}
                                                            onChange={handleZipCodeChange}
                                                            placeholder="Zip Code"
                                                            disabled
                                                            required
                                                        />
                                                        <Label for="zipCode">Zip Code *</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <AutoCompleteInputFormGrouped
                                                        name="experiencedBrandFirst"
                                                        label="Experienced Brand 1"
                                                        placeholder="Experienced Brand 1"
                                                        value={formData.experiencedBrandFirst}
                                                        options={makes}
                                                        onSuggestionClick={(clickedItem) => setFormData({ ...formData, experiencedBrandFirst: clickedItem.name })}
                                                        handleChange={handleChange} />
                                                </Col>
                                                <Col md={4}>
                                                    <AutoCompleteInputFormGrouped
                                                        name="experiencedBrandSecond"
                                                        label="Experienced Brand 2"
                                                        placeholder="Experienced Brand 2"
                                                        value={formData.experiencedBrandSecond}
                                                        options={makes}
                                                        onSuggestionClick={(clickedItem) => setFormData({ ...formData, experiencedBrandSecond: clickedItem.name })}
                                                        handleChange={handleChange} />
                                                </Col>
                                                <Col md={4}>
                                                    <AutoCompleteInputFormGrouped
                                                        name="experiencedBrandThird"
                                                        label="Experienced Brand 3"
                                                        placeholder="Experienced Brand 3"
                                                        value={formData.experiencedBrandThird}
                                                        options={makes}
                                                        onSuggestionClick={(clickedItem) => setFormData({ ...formData, experiencedBrandThird: clickedItem.name })}
                                                        handleChange={handleChange} />
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="textarea"
                                                            id="aseCertification"
                                                            name="aseCertification"
                                                            value={formData.aseCertification}
                                                            placeholder="ASE ID Number"
                                                            required="required"
                                                            data-error="Please, fill up certifications."
                                                            onChange={handleChange}
                                                        />
                                                        <Label for="aseCertification">ASE ID Number *</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="textarea"
                                                            id="workExperience"
                                                            name="workExperience"
                                                            value={formData.workExperience}
                                                            placeholder="Work Experience"
                                                            required="required"
                                                            data-error="Please, fill up experience tab."
                                                            onChange={handleLimitedTextChange}
                                                        />
                                                        <Label for="workExperience">Work Experience *</Label>
                                                        <span style={{ fontSize: 'x-small', float: 'right' }}>{formData.workExperience?.length}/{MAX_LENGTH}</span>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="textarea"
                                                            id="education"
                                                            name="education"
                                                            value={formData.education}
                                                            placeholder="Education"
                                                            required="required"
                                                            data-error="Please, fill up education tab."
                                                            onChange={handleLimitedTextChange}
                                                        />
                                                        <Label for="education">Education *</Label>
                                                        <span style={{ fontSize: 'x-small', float: 'right' }}>{formData.education?.length}/{MAX_LENGTH}</span>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="textarea"
                                                            id="otherCertifications"
                                                            name="otherCertifications"
                                                            value={formData.otherCertifications || ''}
                                                            placeholder="Other Certifications"
                                                            onChange={handleLimitedTextChange}
                                                        />
                                                        <Label for="otherCertifications">Other Certifications</Label>
                                                        <span style={{ fontSize: 'x-small', float: 'right' }}>{formData.otherCertifications ? formData.otherCertifications?.length : 0}/{MAX_LENGTH}</span>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="text"
                                                            id="referralCode"
                                                            name="referralCode"
                                                            value={formData.referralCode || ''}
                                                            placeholder="Referral Code"
                                                            onChange={handleChange}
                                                        />
                                                        <Label for="referralCode">Referral Code</Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={9}>
                                                    <div style={{ color: "red", fontSize: "0.9rem" }}>
                                                        {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <Row>
                                                        <Col md={3}>
                                                        </Col>
                                                        <Col md={9}>
                                                            <button type="submit" className="btn btn-dark my-5">Submit Application</button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </form>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Inspector;
