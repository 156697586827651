import React from 'react'
import { Link } from 'react-router-dom';
function HeroSectionBecomeInspector() {
  const gototop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <>
      <section className="hero-banner position-relative custom-py-1 hero-shape3" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-5 col-xl-6 order-lg-1 mb-8 mb-lg-0">
              {/* <!-- Image --> */}
              <img
                src={require("../../assets/images/inspectiver/01.png")}
                className="img-fluid"
                alt="..."
              />
            </div>
            <div className="col-12 col-lg-7 col-xl-6">
              <h2 className="mb-5">Join Our Team of Expert Inspectors!</h2>
              {/* <!-- Buttons --> */}
              {/* <div  className="btn btn-primary" onClick={gototop}> Order Your Inspection </div> */}
              <blockquote className="mt-5 mb-5 ps-3 border-start border-primary">
                {/* <!-- Text --> */}
                <p className="lead mb-0" style={{ fontWeight: '500' }}>
                  We're actively seeking experienced and highly skilled ASE-certified technicians to join our team.
                  If you're passionate about providing top-notch pre-purchase car inspections and want to be part
                  of a growing network, we'd love to hear from you!
                </p>
                <p className="lead mb-0" style={{ fontWeight: '500' }}>For any questions email us at <a href="mailto:support@inspectiver.com">support@inspectiver.com</a></p>
              </blockquote>

              <Link className="btn btn-dark mt-2" to="inspector">Become an Inpsector</Link>
            </div>
          </div>
          {/* <!-- / .row --> */}
        </div>
        {/* <!-- / .container --> */}
      </section>
    </>
  )
}

export default HeroSectionBecomeInspector
