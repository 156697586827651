
import React from 'react'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import { Container, Row, Col } from 'reactstrap';


const HowItWorks = () => {
    return (
        <>
            <HerosectionHeader name={"How It Works"} />
            <div class="page-content">
                <section>
                    <Container>
                        <Row>
                            <Col lg="3"></Col>
                            <Col lg="6" >
                                <div className="mb-3">
                                    <h2 className="mb-0 text-center" >
                                        For Inspectors
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="3"></Col>
                            <Col lg="6">
                                <div class="card" style={{ backgroundColor: "#bee6dc" }}>
                                    <div class="card-body">
                                        <Row className='row align-items-center'>
                                            <Col lg="2" sm="12" className='text-center'>
                                                <i className='la la-user-circle la-4x'></i>
                                            </Col>
                                            <Col lg="10" sm="12">
                                                <h4 class="card-title font-w-7">Sign Up and Verify</h4>
                                                <p class="card-text">Create your profile, verify your details, and join our network of trusted inspectors.</p>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>
                            <Col lg="3"></Col>
                        </Row>
                        <Row>
                            <div style={{ borderLeft: "3px solid gray", marginLeft: "50%", height: "30px" }}></div>
                        </Row>
                        <Row>
                            <Col lg="3"></Col>
                            <Col lg="6">
                                <div class="card" style={{ backgroundColor: "#b2b2cf" }}>
                                    <div class="card-body">
                                        <Row className='row align-items-center'>
                                            <Col lg="2" sm="12" className='text-center'>
                                                <i className='la la-check-circle la-4x'></i>
                                            </Col>
                                            <Col lg="10" sm="12">
                                                <h4 class="card-title font-w-7">Accept Assignment</h4>
                                                <p class="card-text">Choose the inspection jobs that fit your skills, schedule, and preferences.</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3"></Col>
                        </Row>
                        <Row>
                            <div style={{ borderLeft: "3px solid gray", marginLeft: "50%", height: "30px" }}></div>
                        </Row>
                        <Row>
                            <Col lg="3"></Col>
                            <Col lg="6">
                                <div class="card" style={{ backgroundColor: "#e7cdc2" }}>
                                    <div class="card-body">
                                        <Row className='row align-items-center'>
                                            <Col lg="2" sm="12" className='text-center'>
                                                <i className='la la-car la-4x'></i>
                                            </Col>
                                            <Col lg="10" sm="12">
                                                <h4 class="card-title font-w-7">Perform the Inspection</h4>
                                                <p class="card-text">Use our app to complete inspections and record your findings in real time.</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3"></Col>
                        </Row>
                        <Row>
                            <div style={{ borderLeft: "3px solid gray", marginLeft: "50%", height: "30px" }}></div>
                        </Row>
                        <Row>
                            <Col lg="3"></Col>
                            <Col lg="6">
                                <div class="card" style={{ backgroundColor: "#cba188" }}>
                                    <div class="card-body">
                                        <Row className='row align-items-center'>
                                            <Col lg="2" sm="12" className='text-center'>
                                                <i className='la la-list-alt la-4x'></i>
                                            </Col>
                                            <Col lg="10" sm="12">
                                                <h4 class="card-title font-w-7">Submit the Report</h4>
                                                <p class="card-text">Submit your report, and we’ll review it before sharing it with the client.</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3"></Col>
                        </Row>
                        <Row>
                            <div style={{ borderLeft: "3px solid gray", marginLeft: "50%", height: "30px" }}></div>
                        </Row>
                        <Row>
                            <Col lg="3"></Col>
                            <Col lg="6">
                                <div class="card" style={{ backgroundColor: "#efd6b1" }}>
                                    <div class="card-body">
                                        <Row className='row align-items-center'>
                                            <Col lg="2" sm="12" className='text-center'>
                                                <i className='la la-hand-holding-usd la-4x'></i>
                                            </Col>
                                            <Col lg="10" sm="12">
                                                <h4 class="card-title font-w-7">Track Your Earning</h4>
                                                <p class="card-text">Get paid weekly and keep track of your earnings securely.</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3"></Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    )
}

export default HowItWorks;